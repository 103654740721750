import '@css/main.scss';
import {Header} from './utils/Header';
import {Slider} from './utils/Slider';
import {Accordion} from './utils/Accordion'
import {AjaxLoad} from './utils/AjaxLoad';
import {Timeline} from "./utils/Timeline";
import {Offers} from "./utils/Offers";
import {Form} from "./utils/Form";

window.onload = () => {

  new Header()
  new Slider()
  new Accordion()
  new AjaxLoad()
  new Timeline()
  new Form()
  new Offers()

}
