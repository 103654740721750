export class AjaxLoad {

  currentPage
  maxPages
  cpt

  constructor() {

    document.querySelectorAll('*[ajax-load]').forEach(container => {

      this.currentPage = parseInt(container.getAttribute('data-page'))
      this.maxPages = parseInt(container.getAttribute('data-max-pages'))
      this.cpt = container.getAttribute('data-cpt')

      this.getTrigger().addEventListener("click", event => {

        event.preventDefault()
        this.currentPage++
        container.setAttribute('data-page', this.currentPage)
        this.startLoading(container)
        this.fetch(container)

      })

    })
  }

  startLoading(container) {

    const loaderContainer = document.createElement('div')
    const loader = document.createElement('div')

    loaderContainer.classList.add('loader-container')
    loader.classList.add('loader')
    loaderContainer.append(loader)

    if (!container.classList.contains('is-loading')) {
      container.classList.add('is-loading')
      container.append(loaderContainer)
    }

  }

  fetch(container) {

    fetch('/wp-json/wp/v2/posts?page=' + this.currentPage + '&per_page=15&orderby=date&categories=1', {
      method: 'GET',
    })
      .then(response => response.json())
      .then(data => {

        data.forEach(((post) => {
          const clone = document.querySelector('#post-el').content.cloneNode(true)
          this.clonePost(clone, post)
        }))

        this.stopLoading(container)

        if (this.maxPages === this.currentPage) {
          this.getTrigger().style.display = "none"
        }

      })

  }

  getTrigger() {
    return document.querySelector('#more');
  }

  stopLoading(container) {

    container.classList.remove('is-loading')
    document.querySelector('.loader-container').remove()

  }

  clonePost(clone, post) {

    let title = clone.querySelector('.card-content__title')
    let resume = clone.querySelector('.card-content__resume')
    let link = clone.querySelector('.card-content__button')
    let linkThumbnail = clone.querySelector('.card__thumbnail')
    let thumbnail = clone.querySelector('.card__thumbnail img')

    let container = document.querySelector('.posts')

    console.log(post)

    title.innerHTML = post.title?.rendered
    resume.innerHTML = post.excerpt?.rendered
    link.href = post.link
    linkThumbnail.href = post.link

    if (post?.fimg_url) {
      thumbnail.setAttribute('src', post?.fimg_url)
    }

    container.appendChild(clone)

  }



}
