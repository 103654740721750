export class Form {


  constructor() {

    this.initialize_field()

  }

  initialize_field() {

    let val

    const config = {
      selector: "#autocomplete",
      placeHolder: "Dijon...",
      data: {
        src: async () => {
          try {
            const val = document.getElementById("autocomplete").value
            const source = await fetch(`https://geo.api.gouv.fr/communes?nom=${val}&fields=departement&limit=5`);
            const data = await source.json();

            return data;
          } catch (error) {
            return error;
          }
        },
        keys: ["nom"]
      },
      resultItem: {
        highlight: true,
      },
    }
    const autoCompleteJS = new autoComplete(config);

    document.querySelector("#autocomplete").addEventListener("selection", function (event) {
      val = event.detail.selection.value;
      document.getElementById("autocomplete").value = val.nom;
    })

  }

}
