export class Offers {


  currentPage
  maxPages
  cpt
  agency
  query
  sector
  type
  search

  constructor() {

    document.querySelectorAll('*[ajax-offer]').forEach(container => {

      this.currentPage = parseInt(container.getAttribute('data-page'))
      this.maxPages = parseInt(container.getAttribute('data-max-pages'))
      this.agency = container.getAttribute('data-agency') ?? ''
      this.sector = container.getAttribute('data-sector') ?? ''
      this.type = container.getAttribute('data-type') ?? 'extern'
      this.search = container.getAttribute('data-search') ?? ''

      this.getTrigger().addEventListener("click", event => {

        event.preventDefault()
        this.currentPage++
        container.setAttribute('data-page', this.currentPage)
        this.startLoading(container)
        this.getFetchQuery()
        this.fetch(container)

      })

    })
  }

  getFetchQuery() {
    if (this.agency) {
      this.query = '/wp-json/akyos/v1/offers/agency' + '?page=' + this.currentPage + '&agency=' + this.agency
    } else if (this.sector) {
      this.query = '/wp-json/akyos/v1/offers/sector' + '?page=' + this.currentPage + '&sector=' + this.sector
    } else if (this.search) {
      this.query = '/wp-json/akyos/v1/offers/search' + '?page=' + this.currentPage + this.search
    } else {
      this.query = '/wp-json/akyos/v1/offers' + '?page=' + this.currentPage + '&type=' + this.type
    }
  }


  startLoading(container) {

    const loaderContainer = document.createElement('div')
    const loader = document.createElement('div')

    loaderContainer.classList.add('loader-container')
    loader.classList.add('loader')
    loaderContainer.append(loader)

    if (!container.classList.contains('is-loading')) {
      container.classList.add('is-loading')
      container.append(loaderContainer)
    }

  }

  fetch(container) {

    fetch(this.query, {
      method: 'GET',
    })
      .then(response => response.json())
      .then(data => {

        document.querySelector('div.offers').innerHTML += data

        this.stopLoading(container)

        if (this.maxPages === this.currentPage) {
          this.getTrigger().style.display = "none"
        }

      })

  }

  getTrigger() {
    return document.querySelector('#more');
  }

  stopLoading(container) {

    container.classList.remove('is-loading')
    document.querySelector('.loader-container').remove()

  }

}
